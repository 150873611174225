// Generated by Framer (25dafa9)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["S8TgF7f8T"];

const variantClassNames = {S8TgF7f8T: "framer-v-1pbfayg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "S8TgF7f8T", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "S8TgF7f8T", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-C5vDG", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1pbfayg", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"S8TgF7f8T"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-C5vDG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C5vDG .framer-166rfuw { display: block; }", ".framer-C5vDG .framer-1pbfayg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; min-height: 49px; min-width: 275px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-C5vDG .framer-1pbfayg { gap: 0px; } .framer-C5vDG .framer-1pbfayg > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-C5vDG .framer-1pbfayg > :first-child { margin-left: 0px; } .framer-C5vDG .framer-1pbfayg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 */
const FramerMIiZ16iLv: React.ComponentType<Props> = withCSS(Component, css, "framer-C5vDG") as typeof Component;
export default FramerMIiZ16iLv;

FramerMIiZ16iLv.displayName = "Elements/Tabs Copy";

FramerMIiZ16iLv.defaultProps = {height: 49, width: 275};

addFonts(FramerMIiZ16iLv, [])